import React from 'react'
import ImageSection from '../layouts/ImageSection'

const ProductPage = () => {
  return (
    <div>
      <ImageSection imageUrls={[]}></ImageSection>
    </div>
  )
}

export default ProductPage
